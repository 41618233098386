import React, { useState } from 'react';
import styles from '../index.module.scss';
import { FloatingDisclaimer, SvgIcon } from '@ui-elements';
import { GroupedData, DataHeader } from '../models';
import { TooltipDisclaimer } from '~/common/components/ui-elements';
import { colorBlack, colorGrey } from '~/common/utils';
import cn from 'classnames';

type TableData = {
  groupedData: GroupedData[];
  dataHeader: DataHeader[];
};

const TileView = (props: TableData) => {
  const { groupedData, dataHeader } = props;
  const [tileIndex, setTileIndex] = useState(0);

  const handleClick = (index: number) => {
    if (index < 0) {
      setTileIndex(0);
    } else if (index > dataHeader.length - 1) {
      setTileIndex(dataHeader.length - 1);
    } else {
      setTileIndex(index);
    }
  };

  const imageSrc = React.useMemo(() => {
    if (!!dataHeader && dataHeader.length > tileIndex) {
      return dataHeader[tileIndex].image;
    }
    return '';
  }, [tileIndex, dataHeader]);

  const variantName = React.useMemo(() => {
    if (!!dataHeader && dataHeader.length > tileIndex) {
      return dataHeader[tileIndex].variantName;
    }
    return '';
  }, [tileIndex, dataHeader]);

  return (
    <div className={styles.TileContainer}>
      <div className={styles.TileHeader}>
        <div
          className={cn(styles.TileArrow, {
            [styles.TileArrowDisabled]: tileIndex < 1,
          })}
        >
          <SvgIcon
            type="chevronLeft"
            size={2}
            color={`#${
              tileIndex > 0 && dataHeader.length > 1 ? colorBlack : colorGrey
            }`}
            onClick={() => handleClick(tileIndex - 1)}
          />
        </div>
        <div>
          <img src={imageSrc} />
        </div>
        <div
          className={cn(styles.TileArrow, {
            [styles.TileArrowDisabled]: tileIndex >= dataHeader.length - 1,
          })}
        >
          <SvgIcon
            type="chevronRight"
            size={2}
            color={`#${
              tileIndex < dataHeader.length - 1 ? colorBlack : colorGrey
            }`}
            onClick={() => handleClick(tileIndex + 1)}
          />
        </div>
      </div>
      <div className={styles.TileContentContainer}>
        <div className={styles.TileContentHeader}>{variantName}</div>
        <div className={styles.TileGridContainer}>
          <div className={styles.TileGrid}>
            {groupedData.map((dataContent, index) => {
              return (
                <>
                  <div key={index} className={styles.ContentRowTitle}>
                    <FloatingDisclaimer disclaimer={dataContent.nameDisclaimer}>
                      {({ renderTrigger }) => (
                        <>
                          {dataContent.rowName}
                          {renderTrigger()}
                        </>
                      )}
                    </FloatingDisclaimer>
                  </div>
                  <div className={styles.ContentSpecValue}>
                    <div
                      className={styles.ContentSpec}
                      dangerouslySetInnerHTML={{
                        __html: dataContent.rowValues[tileIndex].value,
                      }}
                    />
                    {!!dataContent.rowValues[tileIndex].disclaimer && (
                      <div className={styles.ContentSpecDisclaimer}>
                        <TooltipDisclaimer
                          disclaimer={
                            dataContent.rowValues[tileIndex].disclaimer
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.ContentRowBorder} />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TileView;
