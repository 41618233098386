import React from 'react';
import styles from '../index.module.scss';

type TableData = {
  pimIdentifier: string;
  variantName: string;
  variantImage: string;
};

const TableHeader = (props: TableData) => {
  return (
    <div className={styles.ContentSpecHeader}>
      <img src={props.variantImage} />
      <span className={styles.ContentRowValue}>{props.variantName}</span>
    </div>
  );
};
export default TableHeader;
