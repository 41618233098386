import React, { useEffect, useState } from 'react';
import styles from '../index.module.scss';
import { GroupedData, DataHeader } from '../models';
import {
  TooltipDisclaimer,
  FloatingDisclaimer,
} from '~/common/components/ui-elements';
import { SvgIcon } from '@ui-elements';
import TableHeader from './TableHeader';
import cn from 'classnames';
import { colorBlack, colorGrey } from '~/common/utils';

type TableData = {
  groupedData: GroupedData[];
  dataHeader: DataHeader[];
};

const FullScrollView = (props: TableData) => {
  const { groupedData, dataHeader } = props;
  const [carouselIndex, setCarouselIndex] = useState(0);
  const handleClick = (index) => {
    if (index + 3 > dataHeader.length) {
      setCarouselIndex(index - 1);
    } else if (index < 0) {
      setCarouselIndex(0);
    } else {
      setCarouselIndex(index);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {dataHeader.length > 3 && (
        <div
          className={cn(styles.TileArrow, {
            [styles.TileArrowDisabled]: carouselIndex < 1,
          })}
        >
          <SvgIcon
            type="chevronLeft"
            size={2.2}
            color={`#${
              carouselIndex > 0 && dataHeader.length > 3
                ? colorBlack
                : colorGrey
            }`}
            onClick={() => handleClick(carouselIndex - 1)}
          />
        </div>
      )}
      <div className={styles.ContainerCaorusel}>
        <div className={styles.DataWrapper}>
          <div className={styles.ContentDataRowNames} />
          <div
            className={cn(styles.ContentDataRowValues, {
              [styles.MinimizeWidth]: dataHeader.length < 3,
            })}
          >
            <div
              className={styles.ContentTransitionWrapper}
              style={{
                transform: `translateX(-${carouselIndex * 33}%)`,
              }}
            >
              <div
                className={cn(styles.GroupRowData, {
                  [styles.SpaceAroundRowHeader]: dataHeader.length < 2,
                })}
              >
                {dataHeader.map((variant, index) => {
                  return (
                    <TableHeader
                      key={index}
                      pimIdentifier={variant.pimIdentifier}
                      variantImage={variant.image}
                      variantName={variant.variantName}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.DataWrapper}>
          <MainTable
            groupedData={groupedData}
            dataHeader={dataHeader}
            carouselIndex={carouselIndex}
          />
        </div>
      </div>

      {dataHeader.length > 3 && (
        <div
          className={cn(styles.TileArrow, {
            [styles.TileArrowDisabled]: carouselIndex >= dataHeader.length - 3,
          })}
        >
          <SvgIcon
            type="chevronRight"
            size={2.2}
            color={`#${
              carouselIndex < dataHeader.length - 3 ? colorBlack : colorGrey
            }`}
            onClick={() => handleClick(carouselIndex + 1)}
          />
        </div>
      )}
    </div>
  );
};
const MainTable = (props) => {
  const { groupedData, dataHeader, carouselIndex } = props;
  const [refHeight, setRefHeight] = useState<number[]>();
  const refs = Array(groupedData.length)
    .fill(0)
    .map(() => React.createRef<HTMLDivElement>());

  useEffect(() => {
    const handleResize = () => {
      setRefHeight(refs.map((x) => x.current?.clientHeight || 0));
    };

    if (!refHeight) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [refs]);

  return (
    <>
      <div className={styles.ContentDataRowNames}>
        {groupedData.map((data: GroupedData, index) => {
          return (
            <>
              <div
                className={cn(styles.ContentRowTitle)}
                key={index}
                ref={refs[index]}
              >
                <FloatingDisclaimer disclaimer={data.nameDisclaimer}>
                  {({ renderTrigger }) => (
                    <>
                      {data.rowName}
                      {renderTrigger()}
                    </>
                  )}
                </FloatingDisclaimer>
              </div>
            </>
          );
        })}
      </div>
      <div
        className={cn(styles.ContentDataRowValues, {
          [styles.MinimizeWidth]: dataHeader.length < 3,
        })}
      >
        <div
          className={styles.ContentTransitionWrapper}
          style={{
            transform: `translateX(-${carouselIndex * 33}%)`,
          }}
        >
          {refHeight &&
            groupedData.map((data, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={cn(styles.GroupRowData, {
                      [styles.SpaceAroundRow]: dataHeader.length < 3,
                    })}
                  >
                    {data.rowValues.map((rowValue, valIndex) => {
                      return (
                        <div
                          key={valIndex}
                          className={styles.ContentSpecValue}
                          style={{
                            ...(refHeight && refHeight[index]
                              ? {
                                  height: `${refHeight[index]}px`,
                                }
                              : {}),
                          }}
                        >
                          <div
                            className={cn(styles.ContentSpec)}
                            dangerouslySetInnerHTML={{
                              __html: rowValue.value,
                            }}
                          />
                          {!!rowValue.disclaimer && (
                            <div className={styles.ContentSpecDisclaimer}>
                              <FloatingDisclaimer
                                disclaimer={rowValue.disclaimer}
                                className={cn(styles.SpecDisclaimer)}
                              >
                                {({ renderTrigger }) => <>{renderTrigger()}</>}
                              </FloatingDisclaimer>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default FullScrollView;
