import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { RangeAndCharging } from './index';
import '~/styles/main.scss';

declare let global;
global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.RangeAndCharging = RangeAndCharging;
