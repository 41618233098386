import React, { useState, useLayoutEffect, useRef } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { SvgIcon } from '@ui-elements';
import { toCamelCase } from '~/common/utils';
import { Button, IconEnum } from '~/common/components/ui-elements';
import { TileView, FullWidthView, FullScrollView } from './components';
import { CallToAction } from '~/common/models';
import stylesHeroCard from '../hero-card/index.module.scss';
import { DataHeader, GroupedData } from './models';

type RangeAndChargingType = {
  heading: string;
  disclaimer: string;
  contentCTAs: CallToAction[];
  groupedData: GroupedData[];
  dataHeader: DataHeader[];
};

export const RangeAndCharging = React.memo((props: RangeAndChargingType) => {
  return <RangeAndChargingContent {...props} />;
});

const DisclaimerView = (props) => {
  const { disclaimer, dataHeader } = props;

  return (
    <div className={styles.ContentDataContainer}>
      <div
        className={cn(styles.ContentData, {
          [styles.MinimizeColumns]: dataHeader.length < 3,
        })}
      >
        <div />
        <div
          className={styles.ContentDisclaimer}
          dangerouslySetInnerHTML={{ __html: disclaimer }}
        />
      </div>
    </div>
  );
};

const MobileDisclaimerView = (props) => {
  const { disclaimer } = props;
  return (
    <div className={styles.ContentDataContainer}>
      <div
        className={styles.ContentDisclaimer}
        dangerouslySetInnerHTML={{ __html: disclaimer }}
      />
    </div>
  );
};

const RangeAndChargingContent = (props: RangeAndChargingType) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isMobileView, setIsMobileView] = useState(false);
  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      if (
        containerRef.current?.clientWidth < 391 ||
        containerRef.current?.clientWidth < 769 ||
        containerRef.current?.clientWidth < 1025
      ) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, []);

  return (
    <div ref={containerRef} style={{ marginBottom: '50px' }}>
      <div className={styles.Header}>
        <div dangerouslySetInnerHTML={{ __html: props.heading }} />
      </div>
      {!isMobileView && (
        <FullScrollView
          groupedData={props.groupedData}
          dataHeader={props.dataHeader}
        />
      )}
      {isMobileView && (
        <TileView
          groupedData={props.groupedData}
          dataHeader={props.dataHeader}
        />
      )}
      {isMobileView && <MobileDisclaimerView disclaimer={props.disclaimer} />}
      {!isMobileView && (
        <DisclaimerView
          isMobileView={isMobileView}
          disclaimer={props.disclaimer}
          dataHeader={props.dataHeader}
        />
      )}
      <div className={styles.ContentDataContainer}>
        <div className={styles.CTAContainer}>
          {props.contentCTAs?.map((cta, idx) => {
            const buttonProps: any = {
              type: toCamelCase(cta.ctaButtonType),
              href: cta.ctaUrl?.url,
              target: cta.ctaUrl?.target ?? '_self',
              onClick: () => {},
            };
            return (
              <Button
                key={idx}
                className={cn(
                  stylesHeroCard.HeroCardCTA,
                  stylesHeroCard[`HeroCardCTA${buttonProps.type}`]
                )}
                {...buttonProps}
              >
                {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                  <SvgIcon
                    className={styles.CTAIcon}
                    type={cta.ctaIcon as keyof typeof IconEnum}
                    color="#1637a0"
                    size={1.2}
                    strokeWidth={2}
                  />
                )}
                <span
                  className={styles.CTAText}
                  style={
                    buttonProps.type === 'text' ? { color: '#1637a0' } : {}
                  }
                >
                  {cta.ctaUrl?.name || ''}
                </span>
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
